<template>
  <b-col
    md="12"
    lg="6"
    cols="12"
  >
    <b-card no-body>
      <b-overlay
        variant="light"
        :show="loadingProductTerlaris"
        spinner-variant="primary"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <div class="flex flex-row p-1">
          <div class="flex">
            <p class="text-2xl my-auto mr-[5px] font-semibold">
              Produk Terlaris</p>
            <img
              id="infoProdukTerlaris"
              class="my-auto"
              src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            >
            <b-popover
              triggers="hover"
              target="infoProdukTerlaris"
              placement="bottomleft"
            >Produk Terlaris merupakan produk kamu yang paling banyak di
              order.</b-popover>
          </div>
          <div class="w-[92px] ml-auto mr-[5px] my-auto">
            <flat-pickr
              v-model="selectedProdukTerlaris"
              :config="flatpickrMonthly"
              @input="getBestSeller()"
            />
          </div>
          <b-button
            variant="outline-secondary"
            class="btn-icon my-auto rounded-lg p-0"
            size="sm"
            :to="{ name: $route.meta.routeToDataProduct}"
          >
            <feather-icon
              icon="ChevronRightIcon"
              size="25"
            />
          </b-button>
        </div>
        <div
          class="lg:h-[35rem] md:h-[41rem] xl:h-[40rem]"
          style="overflow-y:auto;overflow-x:hidden"
        >

          <table
            class="table table-light"
            style="overflow-y:auto;overflow-x:hidden"
          >
            <thead>
              <tr>
                <th>No</th>
                <th class="px-0">
                  Produk
                </th>
                <th class="text-center">
                  Terjual
                </th>
              </tr>
            </thead>
            <tbody v-if="produkTerlarises.length > 0">
              <tr
                v-for="(items, index) in produkTerlarises"
                :key="index"
              >
                <td class="align-top">
                  {{ index + 1 }}
                </td>
                <td class="px-0">
                  <b-row>
                    <div style="width:18%;">
                      <img
                        v-if="items.photo === null"
                        :src="require('@/assets/images/avatars/image-null.png')"
                        class="image-product"
                      >
                      <img
                        v-else
                        :src="items.photo"
                        class="image-product"
                      >
                    </div>
                    <div
                      class="px-1"
                      style="width:82%;"
                    >
                      <span class="my-auto">{{ items.name }}</span><br>
                      <b-row
                        v-if="items.variants.length > 0"
                        v-b-toggle="`collapseVariant${index}`"
                        class="text-center rounded-lg border py-[5px] mt-[5px]"
                        role="button"
                      >
                        <div
                          v-for="(options, idx) in items.variants"
                          :key="idx"
                          :style="`width:${91 / items.variants.length}%`"
                        >
                          {{ options.variant_name }}
                        </div>
                        <div
                          style="width:7%;"
                          class="bg-white text-black rounded-circle"
                        >
                          <b-icon-chevron-down class="when-closed" />
                          <b-icon-chevron-up class="when-open" />
                        </div>
                      </b-row>
                      <span
                        v-else
                        class="font-bold text-muted"
                      >&mdash;</span>
                      <b-collapse
                        :id="`collapseVariant${index}`"
                        class="bg-light mx-[-1rem] p-1"
                      >
                        <div v-if="items.variants.length === 3">
                          <div
                            v-for="(option, idx) in items.options"
                            :key="idx"
                            class="d-flex"
                          >
                            <div
                              class="text-center"
                              style="width:30.33%;"
                            >
                              {{ option.option_name }}
                            </div>
                            <div style="width:69.66%">
                              <div
                                v-for="(child, i) in option.option_child"
                                :key="i"
                                class="d-flex"
                              >
                                <div
                                  class="text-center"
                                  style="width:46%;"
                                >
                                  {{ child.option_name }}
                                </div>
                                <div style="width:46%">
                                  <p
                                    v-for="(child2, x) in child.option_child"
                                    :key="x"
                                    class="text-center mb-[8px]"
                                  >
                                    {{ child2.option_name }}
                                  </p>
                                </div>
                                <div style="width:8%">
                                  <p
                                    v-for="(child2, id) in child.option_child"
                                    :key="id"
                                    class="d-flex justify-content-center mb-[8px]"
                                  >
                                    {{ child2.total_sold }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else-if="items.variants.length === 2">
                          <div
                            v-for="(option, idx) in items.options"
                            :key="idx"
                            class="d-flex"
                          >
                            <div
                              class="text-center"
                              style="width:30.33%;"
                            >
                              {{ option.option_name }}
                            </div>
                            <div style="width:69.66%">
                              <div
                                v-for="(child, i) in option.option_child"
                                :key="i"
                                class="d-flex mb-[8px]"
                              >
                                <div
                                  class="text-center"
                                  style="width:92%;"
                                >
                                  {{ child.option_name }}
                                </div>
                                <div style="width:8%">
                                  <p class="d-flex justify-content-center mb-[8px]">
                                    {{ child.total_sold }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div
                            v-for="(option, idx) in items.options"
                            :key="idx"
                            class="d-flex mb-[8px]"
                          >
                            <div
                              class="text-center"
                              style="width:92%;"
                            >
                              {{ option.option_name }}
                            </div>
                            <div style="width:8%;">
                              <p class="d-flex justify-content-center mb-[8px]">
                                {{ option.total_sold }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                  </b-row>
                </td>
                <td class="text-center align-top">
                  <p class="mb-[8px]">
                    {{ items.total_sold }}
                  </p>
                  <div
                    v-if="items.percentage >= 0"
                    class="d-flex justify-content-center"
                  >
                    <b-badge
                      class="text-success"
                      style="background-color:#DCF3EB;"
                      pill
                    >+{{ items.percentage }}%</b-badge>
                    <div class="ml-[5px]">
                      <img
                        src="@/assets/images/icons/arrow-going-up-alt.svg"
                        alt="arrow-going-up-alt"
                      >
                    </div>
                  </div>
                  <div
                    v-else
                    class="d-flex justify-content-center"
                  >
                    <b-badge
                      class="text-danger"
                      style="background-color:#FFEDED;"
                      pill
                    >{{ items.percentage }}%</b-badge>
                    <div class="ml-[5px]">
                      <img
                        src="@/assets/images/icons/arrow-going-down-alt.svg"
                        alt="arrow-going-down-alt"
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td
                  colspan="3"
                  class="text-center p-2"
                >
                  Belum ada produk terjual
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
    </b-card>
  </b-col>
</template>
<script>
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian } from 'flatpickr/dist/l10n/id'
import MonthSelect from 'flatpickr/dist/plugins/monthSelect'
import '@/@core/scss/vue/libs/vue-flatpicker.scss'
import 'flatpickr/dist/plugins/monthSelect/style.css'

export default {
  components: { flatPickr },
  data() {
    return {
      flatpickrMonthly: {
        mode: 'single',
        locale: Indonesian,
        altInput: true,
        altFormat: 'j/n/Y',
        dateFormat: 'Y-m-d',
        maxDate: 'today',
        disableMobile: true,
        plugins: [
          new MonthSelect({
            shorthand: true,
            dateFormat: 'Y-m-d',
            altFormat: 'M Y',
          }),
        ],
      },
    }
  },
  computed: {
    ...mapState('dashboard', [
      'produkTerlarises',
      'loadingProductTerlaris',
    ]),
    ...mapFields('dashboard', {
      selectedProdukTerlaris: 'selectedProdukTerlaris',
    }),
  },
  methods: {
    getBestSeller() {
      this.$store.dispatch('dashboard/getProdukTerlarises')
    },
  },
}
</script>
<style>
.collapsed .when-open,
.not-collapsed .when-closed {
  display: none;
}
</style>
